import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { API } from 'src/services/api.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-categoria',
  templateUrl: './categoria.component.html',
  styleUrls: ['./categoria.component.scss']
})
export class CategoriaComponent implements OnInit {
  public loading: boolean;
  public category: any;
  public selectedCriteria: any;
  public criteria: Array<any>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private api: API,
  ) {
    this.criteria = [
      { name: 'Mayor precio', value: 0 },
      { name: 'Menor precio', value: 1 },
      //{ name: 'Mas vendidos', value: 2 },
    ];
    this.selectedCriteria = null;
    this.category = null;
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (params && params.slug) this.init(params.slug);
    });
  }

  private init(slug) {
    this.category = null;
    this.loading = true;
    this.api.get('categories', { categoryId: slug }).subscribe(category => {
      this.category = category;
      this.loading = false;
    }, error => {
      console.log('Error', error);
      this.loading = false;
    });
  }

  public orderBy(event?) {
    if (_.isEmpty(this.category && this.category.products)) return;

    let criteria = !event ? this.selectedCriteria : event.target.value;
    
    if (criteria == 0) this.category.products = _.orderBy(this.category.products, ['price'],['desc']);
    else if (criteria == 1) this.category.products = _.orderBy(this.category.products, ['price'],['asc']);
    //else if (criteria == 2) this.category.products = _.orderBy(this.category.products, ['id'],['desc']);
  }
  
  public showProductList() {
    if (this.category.products.length > 0 && !this.loading) return true;
    else return false;
  }

  public setAsFavorite(p) {
    console.log(`Set ${p.name} as favorite <3!`);
  }

  public addToCart(p) {
    console.log(`${p.name} was added to the cart!`);
  }

}
