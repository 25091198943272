import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { routes } from './app.routes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import localeEsAr from '@angular/common/locales/es-AR';

// UTILS
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { NgxGalleryModule } from 'ngx-gallery';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ArchwizardModule } from 'angular-archwizard';
import { SocialLoginModule, AuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from "angular-6-social-login";
import { SanitizeHtmlPipe } from '../pipes/sanitizer.pipe';
import { DisqusModule } from "ngx-disqus";
import { DisqusComponent } from '../disqus/disqus.component';
import 'hammerjs';

// PAGES
import { HomeComponent } from 'src/pages/home/home.component';
import { LiquidacionComponent } from 'src/pages/liquidacion/liquidacion.component';
import { LaEmpresaComponent } from 'src/pages/la-empresa/la-empresa.component';
import { PerfilComponent } from 'src/pages/perfil/perfil.component';
import { MiProductoComponent } from 'src/pages/mi-producto/mi-producto.component';
import { ConsultasComponent } from 'src/pages/consultas/consultas.component';
import { CategoriaComponent } from 'src/pages/categoria/categoria.component';
import { SearchComponent } from 'src/pages/search/search.component';
import { CarritoComponent } from 'src/pages/carrito/carrito.component';
import { LoginComponent } from 'src/pages/login/login.component';

// VIEW MODULES
import { ItemCardComponent } from 'src/pages/view-modules/item-card/item-card.component';

// SERVICES
import { API } from 'src/services/api.service';
import { SharingService } from 'src/services/sharing.service';
import { CarouselComponent } from 'src/pages/carousel/carousel.component';
import { VentasMayorFormComponent } from 'src/pages/ventas-mayor-form/ventas-mayor-form.component';
import { TestimoniosComponent } from 'src/pages/testimonios/testimonios.component';
import { SuccessComponent } from 'src/pages/success/success.component';
import { FailureComponent } from 'src/pages/failure/failure.component';
import { PendingComponent } from 'src/pages/pending/pending.component';
import { SpinnerComponent } from '../spinner/spinner.component';
import { NewsComponent } from 'src/pages/news/news.component';
import { MasVendidosComponent } from '../pages/mas-vendidos/mas-vendidos.component';

// Configs 
export function getAuthServiceConfigs() {
  let config = new AuthServiceConfig(
    [
      {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider('2029929793965649') //provisional id
      },
      {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider('237494784753-td3ktcg721bj93ugif22rev57fb9t99l.apps.googleusercontent.com')
      }
    ]
  );
  return config;
}

registerLocaleData(localeEsAr, 'es-Ar');

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LiquidacionComponent,
    LaEmpresaComponent,
    PerfilComponent,
    MiProductoComponent,
    ConsultasComponent,
    CategoriaComponent,
    SearchComponent,
    CarritoComponent,
    LoginComponent,
    ItemCardComponent,
    CarouselComponent,
    VentasMayorFormComponent,
    TestimoniosComponent,
    SuccessComponent,
    FailureComponent,
    PendingComponent,
    SpinnerComponent,
    NewsComponent,
    MasVendidosComponent,
    SanitizeHtmlPipe,
    DisqusComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
    FormsModule,
    HttpClientModule,
    CarouselModule.forRoot(),
    BsDropdownModule.forRoot(),
    CollapseModule,
    NgxGalleryModule,
    ArchwizardModule,
    TabsModule.forRoot(),
    SocialLoginModule,
    ReactiveFormsModule,
    DisqusModule.forRoot('ximaro.com.ar')
  ],
  providers: [
    API,
    SharingService,
    {
      provide: AuthServiceConfig,
      useFactory: getAuthServiceConfigs
    },
    { provide: LOCALE_ID, useValue: 'es-Ar' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
