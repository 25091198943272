import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
  @Input() pList: any;
  @Input() categoryName: string;
  @Input() ismobile: boolean;

  public products: any;
  public index: number;
  public slides: number;
  private skip: number;
  private next: number;
  private offset: number;
  private interval: any;

  constructor() {}
  
  ngOnInit() {
    this.init();
  }

  private init() {
    this.offset = this.ismobile ? 2 : 4;
    this.skip = 0;
    this.next = this.offset;
    this.index = 1;
    this.slides = Math.ceil(this.pList.length / this.next);
    this.products = this.pList.slice(this.skip, this.next);
    this.startInterval();
  }

  prevSlide() {
    if (this.index == 1) return;

    this.skip = this.skip - this.offset;
    this.next = this.next - this.offset;
    this.products = this.pList.slice(this.skip, this.next);
    this.index--;
    this.resetInterval();
    this.startInterval();
  }

  nextSlide() {
    if (this.index == this.slides) return;

    this.skip = this.skip + this.offset;
    this.next = this.next + this.offset;
    this.products = this.pList.slice(this.skip, this.next);
    this.index++;
    this.resetInterval();
    this.startInterval();
  }

  private resetInterval() {
    clearInterval(this.interval);
  }

  private startInterval() {
    // this.interval = setInterval(() => {
    //   if (this.index < this.slides) this.nextSlide();
    //   else this.init();
    // }, 5 * 1000);
  }
}