import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Globals } from 'src/utils/globals';
import * as _ from 'lodash';
import User from '../domain/user';
import AuthenticationResult from '../domain/authentication-result';

const BASE_URL = Globals.config.URL;

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  public user: User;
  
  constructor(
    private http: HttpClient
  ) {
    this.user = null;
  }

  isAuthenticated() {
    return this.user != null;
  }

  async login(user: User): Promise<AuthenticationResult> {
    let body = new URLSearchParams();
    body.set('email', user.email);
    body.set('password', user.password);
    let httpHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post<AuthenticationResult>(`${BASE_URL}/sessions`, body.toString(), { headers: httpHeaders }).toPromise();
  }

  async logout(): Promise<AuthenticationResult> {
    this.user = null;
    localStorage.removeItem('ximaroUser');
    let user = JSON.parse(localStorage.getItem('ximaroUser'));
    let token = user ? user.token : '';
    let httpHeaders = new HttpHeaders({ 'x-access-token': token});
    return this.http.post<AuthenticationResult>(`${BASE_URL}/sessions`, httpHeaders).toPromise();
  }
}









