import { Component, OnInit } from '@angular/core';
import { SharingService } from 'src/services/sharing.service';
import { API } from 'src/services/api.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-mas-vendidos',
  templateUrl: './mas-vendidos.component.html',
  styleUrls: ['./mas-vendidos.component.scss']
})
export class MasVendidosComponent implements OnInit {
  public productList: any;
  public loading: boolean;

  constructor(
    private api: API,
    private sharingService: SharingService,
  ) {
    this.sharingService.currentBestSellers.subscribe(productList => {
      if (_.isEmpty(productList)) return;
      else this.productList = productList;
    });
  }

  ngOnInit() {
    if (_.isEmpty(this.productList)) this.getProductList();
  }

  private getProductList() {
    this.loading = true;

    this.api.get('bestsellers').subscribe(
      (response: any) => {
        this.productList = response.slice(0, 20);
        this.sharingService.changeBestSellers(this.productList);
      },
      error => { this.loading = false; console.log('Error', error) },
      () => this.loading = false
    );
  }
}
