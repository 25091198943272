import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { API } from 'src/services/api.service';

@Component({
  selector: 'app-consultas',
  templateUrl: './consultas.component.html',
  styleUrls: ['./consultas.component.scss']
})
export class ConsultasComponent implements OnInit {
  public form: FormGroup;
  public submitted: boolean;
  public showAnswer1: boolean;
  public showAnswer2: boolean;
  public showAnswer3: boolean;
  public showAnswer4: boolean;
  public showAnswer5: boolean;
  public showAnswer6: boolean;
  public message: string;
  private user: any;

  constructor(
    private formBuilder: FormBuilder,
    private api: API
  ) {
    this.message = null;
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])],
      phone: [''],
      body: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('ximaroUser')) || null;
  }

  get f() {
    return this.form.controls;
  }

  sendComment() {
    console.log(this.form.value);
    let body = this.form.value;
    
    if (this.user) Object.assign(body, { kind: this.user.kind });
    else Object.assign(body, { kind: 'user' });
    
    this.api.post('queries', body).subscribe(
      success => this.onSuccess(), 
      error => this.onError(error),
      () => {
        setTimeout(() => { this.message = null }, 10 * 1000);
      });
  }

  private onSuccess() {
    this.message = 'La consulta ha sido enviada exitosamente';
    this.submitted = false;
    this.form.reset();
  }

  private onError(err) {
    this.submitted = true;
    this.message = 'Ha ocurrido un error. Por favor intente nuevamente.';
  }
}
