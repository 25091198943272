import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { API } from './api.service';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class SharingService {
  private categoryList = new BehaviorSubject<any>(null);
  currentCategoryList = this.categoryList.asObservable();

  private product = new BehaviorSubject<any>(null);
  currentProduct = this.product.asObservable();

  private productList = new BehaviorSubject<any>(null);
  currentProductList = this.productList.asObservable();

  private productListByCategory = new BehaviorSubject<any>(null);
  currentProductListByCategory = this.productListByCategory.asObservable();

  private cart = new BehaviorSubject<any>(null);
  currentCart = this.cart.asObservable();

  private banners = new BehaviorSubject<any>(null);
  currentBanners = this.banners.asObservable();

  private cities = new BehaviorSubject<any>(null);
  currentCities = this.cities.asObservable();

  private subsidiaries = new BehaviorSubject<any>(null);
  currentSubsidiaries = this.subsidiaries.asObservable();

  private page = new BehaviorSubject<any>(null);
  currentPage = this.page.asObservable();

  private onsale = new BehaviorSubject<any>(null);
  currentOnsale = this.onsale.asObservable();

  private bestSellers = new BehaviorSubject<any>(null);
  currentBestSellers = this.bestSellers.asObservable();

  private news = new BehaviorSubject<any>(null);
  currentNews = this.news.asObservable();

  private installments = new BehaviorSubject<any>(null);
  currentInstallments = this.installments.asObservable();

  private shippingPrice = new BehaviorSubject<any>(null);
  currentshippingPrice = this.shippingPrice.asObservable();

  constructor(
    private api: API
  ) {
    var cart = JSON.parse(localStorage.getItem('cart')) || [];
    this.cart.next(cart);

    this.api.get('categories').subscribe(
      categoryList => this.changeCategoryList(categoryList),
      error => { console.log('Error', error) }
    );

    this.api.get('banners').subscribe(
      response => this.changeBanners(response),
      error => { console.log('Error', error) }
    );

    this.api.get('cities').subscribe(
      response => this.changeCities(response),
      error => { console.log('Error', error) }
    );

    this.api.get('subsidiaries').subscribe(
      response => this.changeSubsidiaries(response),
      error => { console.log('Error', error) }
    );
  }
  
  changeCategoryList(categoryList) {
    this.categoryList.next(categoryList);
  }
  
  changeProductList(productList) {
    this.productList.next(productList);
  }
  
  changeProductListByCategory(productList) {
    this.productListByCategory.next(productList);
  }

  changeBanners(banners) {
    this.banners.next(banners);
  }

  changeCities(cities) {
    this.cities.next(cities);
  }

  changeSubsidiaries(subsidiaries) {
    this.subsidiaries.next(subsidiaries);
  }

  changeProduct(product) {
    this.product.next(product);
  }

  changeOnsale(onsale) {
    this.onsale.next(onsale);
  }

  changeBestSellers(bestSellers) {
    this.bestSellers.next(bestSellers);
  }

  changeNews(news) {
    this.news.next(news);
  }

  changePage(page) {
    this.page.next(page);
  }

  changeInstallments(installments) {
    this.installments.next(installments);
  }

  changeShippingPrice(shippingPrice) {
    this.shippingPrice.next(shippingPrice);
  }

  changeCart(cart) {
    !_.isEmpty(cart) ? localStorage.setItem('cart', JSON.stringify(cart)) : localStorage.removeItem('cart');
    this.cart.next(cart);
  }
}
