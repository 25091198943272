import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import { SharingService } from 'src/services/sharing.service';
import { API } from 'src/services/api.service';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { HttpHeaders } from '@angular/common/http';
import { WizardComponent } from 'angular-archwizard';

const deliveryTimeOptions = [
  { value: 1013, text: 'De 10:00 a 13:00' },
  { value: 1316, text: 'De 13:00 a 16:00' },
  { value: 1619, text: 'De 16:00 a 19:00' },
  { value: 1921, text: 'De 19:00 a 21:00' },
];

const crypto_redes = {
  usdt:[
    { text: 'BEP20', value: "0xb9d0a585a633ee7f32122c52479b91fab8f9a198" },
    { text: 'ERC20', value: "0xb9d0a585a633ee7f32122c52479b91fab8f9a198" },
    { text: 'TRC20', value: "TBbK1nPEAueLPziv8XBPERt44rreKNYkiJ" },
  ],
  eth:[
    { text: 'BEP20', value: "0xb9d0a585a633ee7f32122c52479b91fab8f9a198" },
    { text: 'ERC20', value: "0xb9d0a585a633ee7f32122c52479b91fab8f9a198" },
  ],
  btc:[
    { text: 'BEP20', value: "0xb9d0a585a633ee7f32122c52479b91fab8f9a198"},
    { text: 'BTC', value: "13FirwWnaZRjtyAw1eXsaZSz7KTBCsvqTh" },
  ],
};

@Component({
  selector: 'app-carrito',
  templateUrl: './carrito.component.html',
  styleUrls: ['./carrito.component.scss']
})
export class CarritoComponent implements OnInit {
  public productList: any;
  public total: number;
  public exTotal: number;
  public deliveryCost: number;
  public items: number;
  public user: any;
  public isWholesaler: boolean;
  public loading: boolean;
  public isDelivery: boolean;
  public isSubsidiary: boolean;
  public errorInStock: boolean;
  public deliveryTimeOptions: any;
  public selectedTime: any;
  public installments: any;
  public cities: any;
  public subsidiaries: any;
  public selectedInstallment: any;
  public deliveryForm: FormGroup;
  public subsidiaryForm: FormGroup;
  public paymentForm: FormGroup;
  public addCouponForm: FormGroup;
  public submitted: boolean;
  public paymentMethod: string;
  public shippingPrice: number;
  public outOfStockProducts: number[];
  public mostrarBotonContraEntrega: boolean;
  public discountCouponError: boolean;
  public discountCoupon: any;
  public productsOutOfStock: any;
  public cryptoWallet: string;
  public cryptoTotal: any;
  public cryptoPaymentActive: boolean;
  public current_crypto_redes: any;
  public cryptoRed : string;
  public crypto_timer_id : any;
  public cryptoTimedOut : any;
  public lastCryptoSelected : any;
  public lastCryptoPrice : any;
  public cryptoPaymentData : any;

  @ViewChild(WizardComponent)  public wizard: WizardComponent;

  constructor(
    private sharingService: SharingService,
    private api: API,
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private zone: NgZone,
  ) {
    this.loading = false;
    this.deliveryCost = 0;
    this.productsOutOfStock = [];
    this.current_crypto_redes = [];
    this.cryptoRed = ""
    this.paymentMethod = 'mercado_pago';
    this.mostrarBotonContraEntrega = false;
    this.deliveryTimeOptions = deliveryTimeOptions;
    this.selectedTime = this.deliveryTimeOptions[0].value;
    this.zone.run(() => {
      this.items = 0;
      this.total = 0;
    });
    this.sharingService.currentCart.subscribe(cart => {
      this.productList = cart;
    });
    this.sharingService.currentCities.subscribe(cities => {
      this.cities = cities;
    });
    this.sharingService.currentSubsidiaries.subscribe(subsidiaries => {
      this.subsidiaries = subsidiaries;
    });
    this.sharingService.currentshippingPrice.subscribe(shippingPrice => {
      this.shippingPrice = shippingPrice;
    });
    this.user = JSON.parse(localStorage.getItem('ximaroUser')) || null;
    this.isDelivery = true;
    this.isSubsidiary = false;
    this.errorInStock = false;
    this.discountCouponError = false;
    this.outOfStockProducts = [];
    this.discountCoupon = null;
    this.cryptoWallet = null;
    this.cryptoTotal = 0;
    this.cryptoPaymentActive = false;
    this.crypto_timer_id = null;
    this.cryptoTimedOut = false;
    this.lastCryptoSelected = null;
    this.lastCryptoPrice = null;
    this.cryptoPaymentData = null;
  }

  ngOnInit() {
    let user = JSON.parse(localStorage.getItem('ximaroUser'));
    if (user != null) this.isWholesaler = true;
    
    try{
      const last_time_item_added_to_cart = localStorage.getItem("last_time_item_added_to_cart")
      if(last_time_item_added_to_cart){
        const lastTimeAdded = new Date(last_time_item_added_to_cart);
        const currentTime = new Date();
        const timeDifference = currentTime.valueOf() - lastTimeAdded.valueOf();
        const delete_every_hours = 24
        const twentyFourHoursInMilliseconds = delete_every_hours * 60 * 60 * 1000;
        console.log("timeDifference > twentyFourHoursInMilliseconds", timeDifference > twentyFourHoursInMilliseconds)
        console.log("timeDifference > ", timeDifference )
        console.log("twentyFourHoursInMilliseconds > ", twentyFourHoursInMilliseconds )
        console.log("lastTimeAdded > ", lastTimeAdded )
        if (timeDifference > twentyFourHoursInMilliseconds) {
          localStorage.removeItem('cart')
          localStorage.removeItem('last_time_item_added_to_cart')
        }
      }
    } catch (error){}

    
    
    this.errorInStock = false;
    this.productsOutOfStock = []
    this.calculateTotal();

    this.deliveryForm = this.formBuilder.group({
      streetNumber: ['', Validators.required],
      streetName: ['', Validators.required],
      floor: [''],
      apartment: [''],
      city: ['', Validators.required],
      location: ['', Validators.required],
      zipCode: ['', Validators.required],
      additionalInfo: [''],
      time: [this.selectedTime.toString()]
    });

    this.subsidiaryForm = this.formBuilder.group({
      subsidiary: ['', Validators.required],
      additionalInfo: [''],
      //time: [this.selectedTime.toString()]
    });

    this.addCouponForm = this.formBuilder.group({
      couponCode: ['', Validators.required],
    })


    this.paymentForm = this.formBuilder.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      //dni: ['', Validators.required],
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])],
      areaCode: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      cardNumber: [''],
      expiryDate: [''],
      securityCode: [''],
      selectedInstallment: [this.selectedInstallment.toString()]
    });
  }

  setDeliveryCost() {
    let cityId = this.deliveryForm.get('city').value;
    let city = _.find(this.cities, c => { return c.id == cityId });
    this.deliveryCost = city && city.price || 0;
  }

  get df() {
    return this.deliveryForm.controls;
  }

  public changeDelivery(delivery: boolean) {
    this.isDelivery = delivery;
    if(delivery){
      this.isSubsidiary = false
    } else {
      this.isSubsidiary = true
    }
  }

  public changeSubsidiary(subsidiary: boolean) {
    this.isSubsidiary = subsidiary;
  }

  public changeErrorInStock(errStock: boolean) {
    this.errorInStock = errStock;
  }

  public setMostrarBotonContraEntrega(mostrarBotonContraEntrega: boolean) {
    this.mostrarBotonContraEntrega = mostrarBotonContraEntrega;
  }

  public setTime(event) {
    this.selectedTime = event.target.value;
  }

  public setInstallment(event) {
    this.selectedInstallment = Number(event.target.value);
  }

  public removeFromCart(p) {
    if (!p) return;
    _.remove(this.productList, (_p: any) => { return _p.id == p.id; });
    let itemsToSave: Array<any> = _.uniqBy(this.productList, 'id');
    this.sharingService.changeCart(itemsToSave);
    this.calculateTotal();
  }

  private setDiscountCouponError(e: boolean){
    this.discountCouponError = e
    if(e){
      this.discountCoupon = null
    }
  }

  private setDiscountCoupon(c: any){
    if(!c){
      this.discountCoupon = null
      this.calculateTotal()
    }else {
      this.discountCoupon = {...c}
      this.calculateTotal()
    }
  }

  async addCoupon() {
    let coupon_code = this.addCouponForm.get('couponCode').value

    this.api.get('discount_coupons', {coupon_code: coupon_code}).subscribe(
      (success: any) => {
        this.discountCoupon = {...success.discount_coupon}
        this.calculateTotal()
        this.setDiscountCouponError(false)
      },
      error => {
        this.setDiscountCouponError(true)
      },
      () => this.loading = false)


    console.log("ERROR, d",this.discountCouponError)
    //this.calculateTotal();


  }

  async pay() {
    this.loading = true;

    var preference = {
      sale: {
        cityId: this.deliveryForm.get('city').value,
        delivery: this.isDelivery,
        payer: {
          name: this.paymentForm.get('name').value,
          surname: this.paymentForm.get('surname').value,
          email: this.paymentForm.get('email').value,
          phone: {
            areaCode: this.paymentForm.get('areaCode').value,
            number: this.paymentForm.get('phoneNumber').value,
          },
          dni: null,
        },
      },
      paymentMethod: this.paymentMethod,
      products: _.map(this.productList, p => { return { id: p.id, quantity: p.count } })
    }

    if(this.paymentForm.get('dni')){
      preference.sale.payer.dni = this.paymentForm.get('dni').value
    }

  
    if (this.isDelivery) {
      let cityId = this.deliveryForm.get('city').value;
      let cityName = _.find(this.cities, cityId);

      let receiverAddress = {
        streetName: this.deliveryForm.get('streetName').value,
        streetNumber: this.deliveryForm.get('streetNumber').value,
        floor: this.deliveryForm.get('floor').value,
        apartment: this.deliveryForm.get('apartment').value,
        city: cityName,
        location: this.deliveryForm.get('location').value,
        zipCode: this.deliveryForm.get('zipCode').value,
        time: this.selectedTime,
        additionalInfo: this.deliveryForm.get('additionalInfo').value,
      }
      Object.assign(preference.sale, { receiverAddress });
    } else {
      preference.sale['subsidiaryId'] = this.subsidiaryForm.get('subsidiary').value
      preference.sale['cityId'] = this.subsidiaryForm.get('subsidiary').value
    }

    if (this.paymentMethod == 'tarjetas_provinciales') {
      let cardInfo = {
        number: this.paymentForm.get('cardNumber').value,
        date: this.paymentForm.get('expiryDate').value,
        csv: this.paymentForm.get('securityCode').value,
        installments: this.selectedInstallment,
      };
      Object.assign(preference, { cardInfo: cardInfo });
    }

    if (this.paymentMethod == 'crypto') {
      Object.assign(preference.sale, this.cryptoPaymentData)
    }
    console.log('Preference', preference);

    var paymentMethod = 'mercadopago';
    var isAuthenticated = await this.authService.isAuthenticated();
    let options = {};
    if (isAuthenticated) {
      paymentMethod = 'wholesaler'
      const headers = new HttpHeaders({ 'x-access-token': this.user.token });
      options = { headers: headers };
    }
    else {
      switch (this.paymentMethod) {
        case "mercado_pago":
          paymentMethod = 'mercadopago'
          break;
        case "tarjetas_provinciales":
          paymentMethod = 'card'
          break;
        case "contra_entrega":
        case "transferencia_bancaria":
        case "crypto":
          paymentMethod = 'cash'
          break;
      }
    }
    
    this.api.post(paymentMethod, preference, options).subscribe(
      (success: any) => this.successPayment(success, paymentMethod),
      error => this.router.navigate(['/failure']),
      () => this.loading = false
    );
  }

  private successPayment(response, paymentMethod) {
    window.scroll(0, 0);
    this.sharingService.changeCart(null);

    if (paymentMethod == 'mercadopago')
      return window.open(response.url, '_self');

    if (paymentMethod == 'card')
      return this.router.navigate(['/success']);

    if (paymentMethod == 'cash')
      return this.router.navigate(['/success']);

    if (paymentMethod == 'wholesaler')
      return this.router.navigate(['/success']);
  }

  changePaymentMethod(method: string) {
    this.paymentMethod = method;

    if (method === 'tarjetas_provinciales') {
      this.paymentForm.get('cardNumber').setValidators([Validators.required]);
      this.paymentForm.get('expiryDate').setValidators([Validators.required]);
      this.paymentForm.get('securityCode').setValidators([Validators.required]);
    } else {
      this.paymentForm.get('cardNumber').clearValidators();
      this.paymentForm.get('cardNumber').updateValueAndValidity();
      this.paymentForm.get('expiryDate').clearValidators();
      this.paymentForm.get('expiryDate').updateValueAndValidity();
      this.paymentForm.get('securityCode').clearValidators();
      this.paymentForm.get('securityCode').updateValueAndValidity();
    }
  }

  setQuantity(value, index) {
    let val = Number(value);
    let quantity = val < 0 ? 0 : val;
    this.productList[index].count = quantity;
    this.sharingService.changeCart(this.productList);
    this.productsOutOfStock = []
    this.changeErrorInStock(false)
    this.calculateTotal();
  }

  checkNextIfProvinciaTucuman() {
    //console.log(this.cities, 'San Miguel de TUCUMAN')
    let cityId = this.deliveryForm.get('city').value;
    //let cityName = _.find(this.cities, cityId);
    
    //yerba buena, san miguel y tucuman interior
    if( ((cityId == 24 || cityId == 25 || cityId == 26) && this.isDelivery) || this.isSubsidiary ){
      this.setMostrarBotonContraEntrega(true)
    } else {
      this.setMostrarBotonContraEntrega(false)
    }

    this.wizard.model.navigationMode.goToStep(2)
  }

  checkNextIfProductsAvailable() {
    const slugs = this.productList.map(product => product['slug'])
    const params = { "product_slugs": slugs }
    this.api.get('checkavailability', params).subscribe(response => {
      for(let i = 0; i < response["products"].length; i++){
        const product_av = response["products"][i]
        for(let j = 0; j < this.productList.length; j++){
          const product_carrito = this.productList[j]
          if(product_av.available_quantity <= 0){
            this.productsOutOfStock.push(this.productList[j].id)
            this.changeErrorInStock(true)
          }
        }
        if(response["products"].length-1 === i){
          if(!this.errorInStock){
            this.wizard.model.navigationMode.goToStep(1)
          }
        }
      }
    })
  }

  public isOutOfStock(p){
    return this.productsOutOfStock.includes(p.id)
  }
  
  public onRedSelected(v){
    console.log(v)
    if(v.includes('//')){
      let red_wallet = v.split('//')
      this.cryptoWallet = red_wallet[1]
      this.cryptoRed = red_wallet[0]
    }
  }

  private get_crypto(crypto,dollar_price){
    clearTimeout(this.crypto_timer_id);
    this.cryptoTimedOut = false
    this.cryptoWallet = null
    this.cryptoPaymentActive = false
    this.cryptoTotal = 0
    if(crypto == 'usdt'){
      //this.cryptoWallet = "0x7cd1b701af53e72fb8e1b30f8ad44faa03c69e78"
      this.cryptoTotal = (this.total / dollar_price)
      this.cryptoTotal = this.cryptoTotal.toFixed(2)
      this.lastCryptoPrice = 1.0
      this.lastCryptoSelected = crypto
      this.cryptoPaymentActive = true
      this.current_crypto_redes = [...crypto_redes[crypto]]
    } else { 
      this.api.get('crypto_prices', {crypto}).subscribe(response => {
        //console.log(response)  
        let crypto_price = parseFloat(response["prices"].USD)
        //if(crypto == 'btc') this.cryptoWallet = "186nTyiVwHymgyrwoXLRLoAV3c3c6tpr8h"
        //if(crypto == 'eth') this.cryptoWallet = "0x7cd1b701af53e72fb8e1b30f8ad44faa03c69e78"
        this.cryptoTotal = (this.total / dollar_price) / crypto_price
        this.cryptoTotal = this.cryptoTotal.toFixed(6)
        this.cryptoPaymentActive = true
        this.current_crypto_redes = [...crypto_redes[crypto]]
        this.lastCryptoSelected = crypto
        this.lastCryptoPrice = crypto_price
        this.crypto_timer_id = setTimeout(() => {
          //console.log("se venció el cripto tiempo" + crypto);
          this.cryptoTimedOut = true;
        }, 1000 * 60 * 10); //10 minutos
      })
    }
  }

  public get_crypto_total(crypto){
    this.api.get('dolar_blue_price').subscribe(response => {
      let dollar_price = parseFloat(response["blue"].value_sell)
      this.get_crypto(crypto,dollar_price)
      eval(`$('#cryptoModal').modal("show")`)
      //console.log(response)
    })
  }

  public stamp_crypto_sell(){
    if(!this.cryptoWallet){
      alert("Seleccione una wallet")
      return
    }
    const crypto_payment_data = {}
    crypto_payment_data["crypto_total"] = parseFloat(this.cryptoTotal)
    crypto_payment_data["wallet"] = this.cryptoWallet
    crypto_payment_data["time"] = new Date()
    crypto_payment_data["crypto"] = this.lastCryptoSelected
    crypto_payment_data["crypto_price"] = this.lastCryptoPrice

    this.cryptoPaymentData = crypto_payment_data
    eval(`$('#cryptoModal').modal("hide")`)
  }

  private calculateTotal() {
    if (_.isEmpty(this.productList))
      this.total = 0;
    else {
      let totalWolesalerPrice = _.map(this.productList, p => (p.wholesalerPrice ? p.wholesalerPrice : 0) * p.count);
      let price = _.map(this.productList, p => (p.discount ? p.discount : p.price) * p.count);
      this.total = _.sumBy(this.isWholesaler ? totalWolesalerPrice : price);
      this.exTotal = this.total
      if(this.discountCoupon){
        if(this.discountCoupon.is_fixed_amount){
          this.total = this.total-this.discountCoupon.amount
        } else {
          this.total = this.total-(this.total*this.discountCoupon.amount/100)
        }
      }
    }

    this.items = _.sumBy(this.productList, 'count');

    let c1 = this.total;
    let c3 = Math.ceil(this.total / 3);
    let c6 = Math.ceil(this.total / 6);
    let c12 = Math.ceil(this.total / 12);

    this.installments = [
      { value: 1, text: `1 cuota de $${Intl.NumberFormat().format(c1)}` },
      { value: 3, text: `3 cuotas de $${Intl.NumberFormat().format(c3)} + Interes` },
      { value: 6, text: `6 cuotas de $${Intl.NumberFormat().format(c6)} + Interes` },
      { value: 12, text: `12 cuotas de $${Intl.NumberFormat().format(c12)} + Interes` },
    ];
    this.selectedInstallment = this.installments[0].value;
  }
}
