import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-la-empresa',
  templateUrl: './la-empresa.component.html',
  styleUrls: ['./la-empresa.component.scss']
})
export class LaEmpresaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
